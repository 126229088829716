<!--
bug:
1. 点击上一步无法保存上一步填写的信息
2. 步骤导航的原点样式

 -->

<template>

  <div class="mx-auto mt-50" style="max-width: 800px;">

    <div class="mb-50" style="max-width: 800px;">

      <a-steps progress-dot v-model="step" class="no-click">
        <a-step title="添加交易员"/>
        <a-step title="跟单设置"/>
        <a-step title="交易设置"/>
      </a-steps>

    </div>

    <div class="mb-24">
      <!-- Step 1 -->
      <a-card v-if="step == 0" :bordered="false" class="header-solid" :bodyStyle="{paddingTop: 0 }"
              :headStyle="{paddingBottom: '0' }">
        <template #title>
          <h5 class="mb-0">添加交易员信息</h5>
          <p class="font-regular">你将跟随该交易员同步进行交易操作</p>
        </template>

        <a-form
            :form="form"
            @submit="handleSubmit"
            :hideRequiredMark="true"
        >
          <a-row :gutter="[24]" type="flex" justify="center">
            <a-alert
                type="info"
                style="margin: 20px 0; width: 80%;"
                show-icon
            >
              <p slot="message">
                <span style="font-weight: 600">{{ traderPlatformNname }}交易所延迟提示：</span>
                <br>
                <span class="text-gray-7" style="font-size: 12px">此延迟为交易所官方设置，非跟单工具所导致！</span>
              </p>
              <p slot="description" v-html="traderPlatformDescription"></p>
            </a-alert>

            <a-col :span="14">
              <a-form-item class="mb-10" label="选择交易员所在平台" :colon="false">
                <a-select @change="platformChange" v-decorator="[
                      'trader_platform',
                      {
                        rules: [{ required: true, message: '请选择交易员所在平台' }]
                      }
                    ]">
                  <a-select-option v-for="option in platformOptions" :key="option.id" :id="option.id">
                    {{ option.platform }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <!-- 选择OK交易员 -->
            <a-col :span="14" v-if="stepOneFormData.trader_platform === 1">
              <a-form-item class="mb-10" label="交易员平台ID" :colon="false">
                <a-input placeholder="eg. EEA569A04A98D4E9"
                         v-decorator="['uniqueName',{ rules: [{ required: true, message: '请输入你要跟随的交易员平台ID' }] },]"/>
              </a-form-item>
              <span class="text-gray-6" style="font-weight: 400; font-size: 12px">
                    PC访问交易员主页，在域名末尾，查看交易员ID
                </span>
              <br/>
              <span class="text-gray-6" style="font-weight: 400; font-size: 12px">
                    eg. https://www.okx.com/cn/copy-trading/account/EEA569A04A98D4E9?tab=swap
              </span>
            </a-col>
            <a-col :span="14" v-if="stepOneFormData.trader_platform === 1">
              <a-form-item class="mb-10" label="交易员类型" :colon="false">
                <a-radio-group v-model:value="stepOneFormData.role_type" button-style="solid">
                  <a-radio-button value="1">带单员</a-radio-button>
                  <a-radio-button value="2" class="radio-with-badge">普通用户</a-radio-button>
<!--                  <a-radio-button value="2" @click="riskTip()" class="radio-with-badge">普通用户<span class="badge">测试功能</span></a-radio-button>-->
                </a-radio-group>
              </a-form-item>
              <span class="text-gray-6" style="font-weight: 400; font-size: 12px">
                    交易员类型说明
              </span>
              <br/>
              <span class="text-gray-6" style="font-weight: 400; font-size: 12px">
                    带单员：可以在平台直接跟单的交易员
              </span>
              <br/>
              <span class="text-gray-6" style="font-weight: 400; font-size: 12px">
                    普通用户：可以看到个人交易页面，但是无法在平台直接跟单的交易员
              </span>
              <br/>
              <span class="text-gray-6" style="font-weight: 400; font-size: 12px">
                <a-icon type="warning" theme="outlined"/>
                    请注意，只支持没有隐藏实盘仓位的交易员，错误选择交易员将导致无法跟单！
              </span>
            </a-col>
            <!-- 选择OK交易员 -->

            <!-- 选择币安交易员 -->
            <a-col :span="14" v-if="stepOneFormData.trader_platform === 2">
              <a-form-item class="mb-10" label="交易员平台ID" :colon="false">
                <a-input placeholder="eg. 3887627985594221568"
                         v-decorator="['uniqueName',{ rules: [{ required: true, message: '请输入你要跟随的交易员平台ID' }] },]"/>
              </a-form-item>
              <span class="text-gray-6" style="font-weight: 400; font-size: 12px">
                    PC访问交易员主页，在域名末尾，查看交易员ID
                </span>
              <br/>
              <span class="text-gray-6" style="font-weight: 400; font-size: 12px">
                    eg. https://www.binance.com/zh-CN/copy-trading/lead-details/3887627985594221568?timeRange=7D
              </span>
            </a-col>
            <a-col :span="14" v-if="stepOneFormData.trader_platform === 2">
              <a-form-item class="mb-10" label="交易员实盘类型" :colon="false">
                <a-radio-group v-model:value="stepOneFormData.role_type" button-style="solid">
                  <a-radio-button value="1">公开实盘</a-radio-button>
                  <a-radio-button value="2" class="radio-with-badge">隐藏实盘</a-radio-button>
                </a-radio-group>
              </a-form-item>
              <span class="text-gray-6" style="font-weight: 400; font-size: 12px">
                    交易员实盘类型说明
              </span>
              <br/>
              <span class="text-gray-6" style="font-weight: 400; font-size: 12px">
                    公开实盘：可以在平台直接看到交易员的当前仓位
              </span>
              <br/>
              <span class="text-gray-6" style="font-weight: 400; font-size: 12px">
                    隐藏实盘：交易员隐藏当前持仓，只有在跟单状态下才能看到仓位信息
              </span>
              <br/>
              <span class="text-gray-6" style="font-weight: 400; font-size: 12px">
                <a-icon type="warning" theme="outlined"/>
                    请注意，只支持公域跟单(能看到历史持仓的项目)，无法支持币安私域跟单，选择错误项目类型将导致无法跟单！
              </span>
            </a-col>
            <!-- 选择币安交易员 -->
          </a-row>
          <a-row :gutter="[24]" style="margin-top: 20px">
            <a-col :span="12">
            </a-col>
            <a-col :span="12" class="text-right">
              <a-button type="primary" @click="moveStep(1)" class="px-25"> 下 一 步</a-button>
            </a-col>
          </a-row>
        </a-form>
      </a-card>
      <!-- Step 2 -->
      <a-card v-else-if="step == 1" :bordered="false" class="header-solid" :bodyStyle="{paddingTop: 0 }"
              :headStyle="{paddingBottom: '0' }">
        <template #title>
          <h5 class="mb-0">跟单设置</h5>
        </template>
        <a-form
            :form="form"
            @submit="handleSubmit"
            :hideRequiredMark="true"
        >
          <a-row :gutter="[24]" type="flex" justify="center">
            <a-col :span="14">
              <a-form-item class="mb-10" label="选择你的跟单API" :colon="false">
                <a-select v-model="stepTwoFormData.id"  v-decorator="[
                      'api',
                      {
                        rules: [{ required: true, message: '选择你的跟单API' }]
                      }
                    ]">
                  <a-select-option v-for="option in apiOptions" :key="option.id" :id="option.id">
                    {{ option.api_name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <!-- OK跟单模式 -->
            <a-col :span="14" v-if="stepOneFormData.trader_platform === 1">
              <a-form-item class="mb-10" label="跟单模式" :colon="false">
                <a-radio-group v-model:value="stepTwoFormData.follow_type" button-style="solid">
                  <a-radio-button value="1">固定金额</a-radio-button>
                  <a-radio-button v-if="stepOneFormData.role_type == 1" value="2">固定比例</a-radio-button>
<!--                  <a-radio-button @click="chooseSmart()" v-if="stepOneFormData.role_type == 1" value="3">智能跟单</a-radio-button>-->
                  <a-radio-button v-if="stepOneFormData.role_type == 2" value="2">智能跟单</a-radio-button>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <!-- OK跟单模式 -->

            <!-- 币安跟单模式 -->
            <a-col :span="14" v-if="stepOneFormData.trader_platform === 2">
              <a-form-item class="mb-10" label="跟单模式" :colon="false">
                <a-radio-group v-model="stepTwoFormData.follow_type" button-style="solid">
                  <a-radio-button value="2">智能跟单</a-radio-button>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <!-- 币安跟单模式 -->

            <a-col :span="14" v-if="!(stepOneFormData.trader_platform == 1 && stepOneFormData.role_type == 1) && stepTwoFormData.follow_type != 1" style="margin-bottom:10px;margin-top:5px;background-color: #e9f7fe;padding: 10px;border-radius: 5px">
              <a-row type="flex" align="middle">
                <a-col :span="24" :md="12" class="col-info">
                  <div class="avatar-info">
                    <p class="font-semibold m-0" style="font-size: 13px;font-weight: 600;color: #141414;">倍投模式 (可选)</p>
                  </div>
                </a-col>
                <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
                  <small class="mr-5" v-if="stepTwoFormData.multiple_visible === false">关闭</small>
                  <small class="mr-5" v-if="stepTwoFormData.multiple_visible === true">开启</small>
                  <a-switch default-checked v-model="stepTwoFormData.multiple_visible" @change="onChange"/>
                </a-col>
              </a-row>
              <a-form-item class="mb-10" label="加倍倍数(默认为1)" :colon="false" v-if="stepTwoFormData.multiple_visible">
                <a-input placeholder="eg. 2（在原有开单保证金的基础上 * 2，多承担一倍爆仓风险）"
                         v-decorator="['multiple',{ rules: [
                             { required: true, message: '请输入你要加倍投注的倍数！' },
                             { min: 1, message: '长度不能少于1位！' },
                             { validator: (rule, value, callback) => {
                                const numberValue = parseFloat(value);
                                if (isNaN(numberValue) || !isFinite(numberValue)) {
                                  callback('请输入有效的数字！');
                                } else if (numberValue < 1) {
                                  callback('输入数值必须大于等于1');
                                } else if (!/^\d+(\.\d+)?$/.test(value)) {
                                  callback('请输入标准的数字格式，不要包含非数字字符');
                                }else {
                                  callback();
                                }
                              },
                              // message: '输入数值必须大于5',
                              trigger: 'change'
                            },
                          ]
                         },]"/>
              </a-form-item>
              <div v-if="stepTwoFormData.multiple_visible">
                <span class="text-primary" style="font-weight: 400; font-size: 12px">
                    【加倍模式说明】
                </span>
                <br/>
                <span class="text-primary" style="font-weight: 400; font-size: 12px">
                      只适用于经常小金额高杠杆交易的交易员。
                </span>
                <br/>
                <span style="font-weight: 400; font-size: 12px; color: red">
                      如果跟单的交易员经常满仓交易，请不要使用此功能！
                </span>
              </div>
            </a-col>
          </a-row>
          <!-- OK跟单模式 -->
          <a-row :gutter="[24]" type="flex" justify="center" v-if="stepOneFormData.trader_platform === 1">
            <a-col v-if="stepTwoFormData.follow_type == 1" :span="14">
              <a-form-item class="mb-10" label="单笔跟单金额（保证金，推荐金额=总资产/杠杆，自行增减）" :colon="false">
                <a-input placeholder="eg. 100（每次开仓或加仓，都会固定投入100U）" prefix="$" suffix="USDT"
                         v-decorator="['sums',{ rules: [
                             { required: true, message: '请输入你的单笔跟单金额！' },
                             { min: 1, message: '长度不能少于1位！' },
                             { validator: (rule, value, callback) => {
                                const numberValue = parseFloat(value);
                                if (isNaN(numberValue) || !isFinite(numberValue)) {
                                  callback('请输入有效的数字！');
                                } else if (numberValue < 1) {
                                  callback('输入数值必须大于1');
                                } else if (!/^\d+(\.\d+)?$/.test(value)) {
                                  callback('请输入标准的数字格式，不要包含非数字字符');
                                }else {
                                  callback();
                                }
                              },
                              // message: '输入数值必须大于5',
                              trigger: 'change'
                            },
                          ]
                         },]"/>
              </a-form-item>
            </a-col>
            <a-col v-if="stepTwoFormData.follow_type == 2 && stepOneFormData.role_type == 1" :span="14">
              <a-form-item class="mb-10" label="跟单比例（交易员本金1W，你本金1K，比例为0.1，以此类推）" :colon="false">
                <a-input placeholder="eg. 0.1（每次开仓或加仓，都会按交易员仓位的10%投入）"
                               v-decorator="['ratio',{ rules: [
                     { required: true, message: '请输入你的单笔跟单比例！' },
                     { validator: (rule, value, callback) => {
                        const numberValue = parseFloat(value);
                        if (isNaN(numberValue) || !isFinite(numberValue)) {
                          callback('请输入有效的数字！');
                        } else if (numberValue < 0) {
                          callback('输入数值必须大于0');
                        } else if (!/^\d+(\.\d+)?$/.test(value)) {
                          callback('请输入标准的数字格式，不要包含非数字字符');
                        } else {
                          callback();
                        }
                      },
                      trigger: 'change'
                    },
                  ]
                 },]"/>
              </a-form-item>
              <span class="text-primary" style="font-weight: 400; font-size: 12px">
                    【固定比例跟单说明】
              </span>
              <br/>
              <span class="text-primary" style="font-weight: 400; font-size: 12px">
                    比例设置过低将导致无法开仓，请谨慎合理设置。
              </span>
              <br/>
            </a-col>
            <a-col v-if="stepTwoFormData.follow_type == 2 && stepOneFormData.role_type == 2" :span="14">
              <a-form-item class="mb-10" label="投资额（将作为计算每次开单的金额基数）" :colon="false">
                <a-input placeholder=">100 注意：金额过低可能会无法开单" prefix="$" suffix="USDT"
                         v-decorator="['investment',{ rules: [
                             { required: true, message: '请输入你的投资额！' },
                             { min: 1, message: '长度不能少于1位！' },
                             { validator: (rule, value, callback) => {
                                const numberValue = parseFloat(value);
                                if (isNaN(numberValue) || !isFinite(numberValue)) {
                                  callback('请输入有效的数字！');
                                } else if (numberValue < 100) {
                                  callback('输入数值必须大于100');
                                } else {
                                  callback();
                                }
                              },
                              trigger: 'change'
                            },
                          ]
                         },]"/>
              </a-form-item>
              <span class="text-primary" style="font-weight: 400; font-size: 12px">
                    【智能跟单说明】
              </span>
              <br/>
              <span class="text-primary" style="font-weight: 400; font-size: 12px">
                    按照交易员的仓位大小进行跟单。仓位大小 = 当前仓位价值 / 交易账户权益
              </span>
              <br/>
              <span class="text-primary" style="font-weight: 400; font-size: 12px">
                    会智能计算仓位价值，按和交易员同样的仓位占比进行下单。
              </span>
              <br/>
              <span class="text-primary" style="font-weight: 400; font-size: 12px">
                    跟单后如果交易员对现有仓位进行加仓，会立刻复制该品种仓位大小。可能平均开仓金额会高于交易员的平均开仓金额。
              </span>
              <br/>
              <span class="text-primary" style="font-weight: 400; font-size: 12px">
                    建议当交易员空仓时，或者出现亏损时再进行智能跟单，如此利益才能最大化。
              </span>
              <br/>
              <span class="text-primary" style="font-weight: 400; font-size: 12px;color: #EC575BFF">
                    并非只会亏损完投资额就会结束跟单。只要本金足够就会一直跟单。强烈建议实际本金要大于投资额。
              </span>
            </a-col>
            <a-col v-if="stepOneFormData.role_type == 2 && stepTwoFormData.follow_type == 1" :span="14">
              <a-form-item class="mb-10" label="单次减仓比例（每当交易员进行减仓操作时，您将按照这个固定比例进行减仓）" :colon="false">
                <a-input placeholder="eg. 0.5（每次减仓，都会在现有仓位基础上固定减去50%的仓位）"
                         v-decorator="['reduce_ratio',{ rules: [
                             { required: true, message: '请输入你的减仓比例！' },
                             { validator: (rule, value, callback) => {
                                const numberValue = parseFloat(value);
                                if (isNaN(numberValue) || !isFinite(numberValue)) {
                                  callback('请输入有效的数字！');
                                } else if (numberValue <= 0) {
                                  callback('输入数值必须大于0');
                                } else if (numberValue >= 1) {
                                  callback('输入数值必须小于1');
                                } else {
                                  callback();
                                }
                              },
                              trigger: 'change'
                            },
                          ]
                         },]"/>
              </a-form-item>
            </a-col>
          </a-row>
          <!-- OK跟单模式 -->

          <!-- 币安跟单模式 -->
          <a-row :gutter="[24]" type="flex" justify="center" v-if="stepOneFormData.trader_platform === 2">
            <a-col v-if="stepTwoFormData.follow_type == 2" :span="14">
              <a-form-item class="mb-10" label="投资额（将作为计算每次开单的金额基数）" :colon="false">
                <a-input placeholder=">100 注意：金额过低可能会无法开单" prefix="$" suffix="USDT"
                         v-decorator="['investment',{ rules: [
                             { required: true, message: '请输入你的投资额！' },
                             { min: 1, message: '长度不能少于1位！' },
                             { validator: (rule, value, callback) => {
                                const numberValue = parseFloat(value);
                                if (isNaN(numberValue) || !isFinite(numberValue)) {
                                  callback('请输入有效的数字！');
                                } else if (numberValue < 100) {
                                  callback('输入数值必须大于100');
                                } else {
                                  callback();
                                }
                              },
                              trigger: 'change'
                            },
                          ]
                         },]"/>
              </a-form-item>
              <span class="text-primary" style="font-weight: 400; font-size: 12px">
                    【智能跟单说明】
              </span>
              <br/>
              <span class="text-primary" style="font-weight: 400; font-size: 12px">
                    按照交易员的仓位大小进行跟单。仓位大小 = 当前仓位价值 / 交易账户权益
              </span>
              <br/>
              <span class="text-primary" style="font-weight: 400; font-size: 12px">
                    会智能计算仓位价值，按和交易员同样的仓位占比进行下单。
              </span>
              <br/>
            </a-col>
          </a-row>
          <!-- 币安跟单模式 -->

          <a-row :gutter="[24]" type="flex" justify="center">
            <a-col :span="14">
              <a-form-item class="mb-10" label="杠杆设置" :colon="false">
                <a-radio-group v-model:value="stepTwoFormData.lever_set" button-style="solid" style="margin-right: 10px">
                  <a-radio-button :disabled="isTraderPlatformTwo" value="1">跟随交易员</a-radio-button>
                  <a-radio-button v-if="!(stepOneFormData.trader_platform === 1 && stepOneFormData.role_type === '2' && stepTwoFormData.follow_type === '2')"  value="2">自定义杠杆</a-radio-button>
                </a-radio-group>
                <a-input v-if="stepTwoFormData.lever_set === '2'" placeholder="1至50之间任意整数" style="width: 200px"
                         v-decorator="['leverage',{ rules: [
                             { validator: (rule, value, callback) => {
                                const numberValue = parseFloat(value);
                                if (isNaN(numberValue) || !isFinite(numberValue)) {
                                  callback('请输入有效的数字！');
                                } else if (numberValue < 1) {
                                  callback('输入数值必须大于1');
                                } else if (numberValue > 50) {
                                  callback('输入数值必须小于51');
                                } else if (!/^\d+(\.\d+)?$/.test(value)) {
                                  callback('请输入标准的数字格式，不要包含非数字字符');
                                }else {
                                  callback();
                                }
                              },
                              trigger: 'change'
                            },
                          ]
                         },]"/>
              </a-form-item>
              <div v-if="stepTwoFormData.lever_set === '2'">
                <span class="text-primary" style="font-weight: 400; font-size: 12px">
                  【自定义杠杆说明】
                </span>
                <br/>
                <span class="text-primary" style="font-weight: 400; font-size: 12px">
                  假设自定义杠杆为20，即代表可以开出价值20倍本金的持仓。
                </span>
                <br/>
                <span class="text-primary" style="font-weight: 400; font-size: 12px">
                  相反，若设置下单杠杆为3，此时当交易员的净杠杆(即持仓价值与本金的比值)超过3倍，那您的本金即以全部作为保证金而无法再加仓或开新的仓位。
                </span>
              </div>
            </a-col>
          </a-row>


          <a-row :gutter="[24]" type="flex" justify="center">
            <!-- ok跟单 开仓模式 -->
            <a-col :span="14"  v-if="stepOneFormData.trader_platform === 1">
              <a-form-item class="mb-10" label="开仓模式（交易员每新开一个币种合约时生效）" :colon="false">
                <a-radio-group v-model:value="stepTwoFormData.first_open_type" button-style="solid">
                  <a-radio-button :value="1" :disabled="isTraderPlatformOne">当前市价</a-radio-button>
                  <a-radio-button :value="2">区间委托</a-radio-button>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <!-- ok跟单 开仓模式 -->

            <!-- 币安跟单 开仓模式 -->
            <a-col :span="14"  v-if="stepOneFormData.trader_platform === 2">
              <a-form-item class="mb-10" label="开仓模式（交易员每新开一个币种合约时生效）" :colon="false">
                <a-radio-group v-model:value="stepTwoFormData.first_open_type" button-style="solid">
                  <a-radio-button :value="1">当前市价</a-radio-button>
                  <a-radio-button :value="2">区间委托</a-radio-button>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <!-- 币安跟单 开仓模式 -->

            <a-col :span="14" v-if="stepTwoFormData.first_open_type == 2">
              <a-form-item class="mb-10" label="交易员收益区间" :colon="false">
                <a-input placeholder="5（交易员收益率小于5%时开仓），-5（亏损大于5%时开仓）" prefix=" < " suffix="%"
                         v-model="stepTwoFormData.uplRatio"
                         v-decorator="['uplRatio',{ rules: [
                     { required: true, message: '请输入你的止盈百分比！' },
                     { validator: (rule, value, callback) => {
                        const numberValue = parseFloat(value);
                        if (isNaN(numberValue) || !isFinite(numberValue)) {
                          callback('请输入有效的数字！');
                        } else if (!/^-?\d+(\.\d+)?$/.test(value)) {
                          callback('请输入标准的数字格式，不要包含非数字字符');
                        } else {
                          callback();
                        }
                      },
                      trigger: 'change'
                    },
                  ]
                 },]"/>
              </a-form-item>
              <span class="text-primary" style="font-weight: 400; font-size: 12px">
                    【开仓模式说明】
              </span>
              <br v-if="stepOneFormData.trader_platform === 1"/>
              <span class="text-primary" style="font-weight: 400; font-size: 12px" v-if="stepOneFormData.trader_platform === 1">
                      由于okx交易所限制，持仓信息在交易员开仓后5分钟才展示，所以无法进行实时跟单。
              </span>
              <br v-if="stepOneFormData.trader_platform === 1"/>
              <span class="text-primary" style="font-weight: 400; font-size: 12px" v-if="stepOneFormData.trader_platform === 1">
                      除了开仓外，加减仓和平仓均不受影响，可做到实时跟单。
              </span>
              <br />
              <span class="text-primary" style="font-weight: 400; font-size: 12px">
                      区间限价开仓只在交易员新开仓时生效，加仓时不生效。该模式在其他交易所也可使用。
              </span>
              <br />
              <span class="text-primary" style="font-weight: 400; font-size: 12px">
                      系统会自动监控最合适的进场点位第一时间开仓。
              </span>
            </a-col>
          </a-row>
          <a-row :gutter="[24]" style="margin-top: 20px">
            <a-col :span="12">
              <a-button @click="moveStep(-1)" class="px-25"> 上 一 步</a-button>
            </a-col>
            <a-col :span="12" class="text-right">
              <a-button type="primary" @click="moveStep(1)" class="px-25"> 下 一 步</a-button>
            </a-col>
          </a-row>
        </a-form>
      </a-card>
      <!-- Step 3 -->
      <a-card v-else-if="step == 2" :bordered="false" class="header-solid" :bodyStyle="{paddingTop: 0 }"
              :headStyle="{paddingBottom: '0' }">
        <template #title>
          <h5 class="mb-0">交易设置</h5>
        </template>
        <a-form
            :form="form"
            @submit="handleSubmit"
            :hideRequiredMark="true"
        >
          <a-row :gutter="[24]" type="flex" justify="center">
            <a-col :span="14">
              <a-form-item class="mb-10" label="首单跟单设置" :colon="false">
                <a-radio-group v-model:value="stepThreeFormData.first_order_set" button-style="solid">
                  <a-radio-button value="1">有新的加仓，立即跟单</a-radio-button>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col :span="14">
              <a-divider>高级设置</a-divider>
            </a-col>
            <a-col :span="14">
              <a-row type="flex" align="middle" style="margin-top: 20px;">
                <a-col :span="24" :md="12" class="col-info">
                  <div class="avatar-info">
                    <p class="font-semibold m-0" style="font-size: 13px;font-weight: 600;color: #141414;">🔁 反向跟单 ( 娱乐功能 )</p>
                  </div>
                </a-col>
                <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
                  <small class="mr-5" v-if="stepThreeFormData.posSide_set_visible === false">关闭</small>
                  <small class="mr-5" v-if="stepThreeFormData.posSide_set_visible === true">开启</small>
                  <a-switch default-checked v-model="stepThreeFormData.posSide_set_visible" @change="onChange"/>
                </a-col>
              </a-row>
            </a-col>
            <a-col :span="14">
<!--              <a-form-item class="mb-10" label="【限时免费】🚀 极速跟单" :colon="false">-->
<!--                <a-radio-group v-model:value="stepThreeFormData.fast_mode" button-style="solid">-->
<!--                  <a-radio-button value="0">关闭</a-radio-button>-->
<!--                  <a-radio-button value="1">开启</a-radio-button>-->
<!--                </a-radio-group>-->
<!--              </a-form-item>-->
              <a-row type="flex" align="middle" style="margin-top: 20px;">
                <a-col :span="24" :md="12" class="col-info">
                  <div class="avatar-info">
                    <p class="font-semibold m-0" style="font-size: 13px;font-weight: 600;color: #141414;">🚀 极速跟单 ( 限时免费 )</p>
                  </div>
                </a-col>
                <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
                  <small class="mr-5" v-if="stepThreeFormData.fast_mode_visible === false">关闭</small>
                  <small class="mr-5" v-if="stepThreeFormData.fast_mode_visible === true">开启</small>
                  <a-switch default-checked v-model="stepThreeFormData.fast_mode_visible" @change="onChange"/>
                </a-col>
              </a-row>
            </a-col>
            <a-col :span="14">
              <a-row type="flex" align="middle" style="margin-top: 20px;">
                <a-col :span="24" :md="12" class="col-info">
                  <div class="avatar-info">
                    <p class="font-semibold m-0" style="font-size: 13px;font-weight: 600;color: #141414;">⚠️ 交易止盈止损</p>
                  </div>
                </a-col>
                <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
                  <small class="mr-5" v-if="stepThreeFormData.trade_trigger_visible === false">关闭</small>
                  <small class="mr-5" v-if="stepThreeFormData.trade_trigger_visible === true">开启</small>
                  <a-switch default-checked v-model="stepThreeFormData.trade_trigger_visible" @change="onChange"/>
                </a-col>
              </a-row>
            </a-col>
            <a-col :span="14" style="margin-top: 20px;" v-if="stepThreeFormData.trade_trigger_visible">
              <a-form-item class="mb-10" label="止盈百分比" :colon="false">
                <a-input placeholder="eg. 20（每笔交易盈利20%时止盈），0为不设置止盈" suffix="%"
                         v-model="stepThreeFormData.tp_trigger_px"
                         v-decorator="['tp_trigger_px',{ rules: [
                             { required: true, message: '请输入你的止盈百分比！' },
                             { validator: (rule, value, callback) => {
                                const numberValue = parseFloat(value);
                                if (isNaN(numberValue) || !isFinite(numberValue)) {
                                  callback('请输入有效的数字！');
                                } else if (numberValue < 0) {
                                  callback('输入数值必须大于0');
                                }  else if (!/^\d+(\.\d+)?$/.test(value)) {
                                  callback('请输入标准的数字格式，不要包含非数字字符');
                                }else {
                                  callback();
                                }
                              },
                              trigger: 'change'
                            },
                          ]
                         },]"/>
              </a-form-item>
              <a-form-item class="mb-10" label="止损百分比" :colon="false">
                <a-input placeholder="eg. 20（每笔交易亏损20%时止损），0为不设置止损" suffix="%"
                         v-model="stepThreeFormData.sl_trigger_px"
                         v-decorator="['sl_trigger_px',{ rules: [
                             { required: true, message: '请输入你的止损百分比！' },
                             { validator: (rule, value, callback) => {
                                const numberValue = parseFloat(value);
                                if (isNaN(numberValue) || !isFinite(numberValue)) {
                                  callback('请输入有效的数字！');
                                } else if (numberValue < 0) {
                                  callback('输入数值必须大于0');
                                }  else if (!/^\d+(\.\d+)?$/.test(value)) {
                                  callback('请输入标准的数字格式，不要包含非数字字符');
                                }else {
                                  callback();
                                }
                              },
                              trigger: 'change'
                            },
                          ]
                         },]"/>
              </a-form-item>
            </a-col>
            <a-col :span="14">
              <a-row type="flex" align="middle" style="margin-top: 20px;">
                <a-col :span="24" :md="12" class="col-info">
                  <div class="avatar-info">
                    <p class="font-semibold m-0" style="font-size: 13px;font-weight: 600;color: #141414;">⚠️ 多空开仓策略</p>
                  </div>
                </a-col>
                <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
                  <small class="mr-5" v-if="stepThreeFormData.pos_visible === false">关闭</small>
                  <small class="mr-5" v-if="stepThreeFormData.pos_visible === true">开启</small>
                  <a-switch v-model="stepThreeFormData.pos_visible" @change="onChange"/>
                </a-col>
                <a-col :span="14" v-if="stepThreeFormData.pos_visible">
                  <a-form-item class="mb-10" label="多空开仓策略设置" :colon="false">
                    <a-radio-group v-model="stepThreeFormData.pos_value" button-style="solid">
                      <a-radio-button value="long">
                        只跟多单
                      </a-radio-button>
                      <a-radio-button value="short">
                        只跟空单
                      </a-radio-button>
                    </a-radio-group>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-col>
            <a-col :span="14">
              <a-row type="flex" align="middle" style="margin-top: 20px;">
                <a-col :span="24" :md="12" class="col-info">
                  <div class="avatar-info">
                    <p class="font-semibold m-0" style="font-size: 13px;font-weight: 600;color: #141414;">💹 24h成交量排行榜跟单</p>
                  </div>
                </a-col>
                <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
                  <small class="mr-5" v-if="stepThreeFormData.vol24h_visible === false">关闭</small>
                  <small class="mr-5" v-if="stepThreeFormData.vol24h_visible === true">开启</small>
                  <a-switch v-model="stepThreeFormData.vol24h_visible" @change="onChange"/>
                </a-col>
              </a-row>
            </a-col>
            <a-col :span="14" style="margin-top: 20px;" v-if="stepThreeFormData.vol24h_visible">
              <a-form-item class="mb-10" label="榜单排名" :colon="false">
                <a-input placeholder="eg. 20（只跟排行榜前20的币种）"
                         v-model="stepThreeFormData.vol24h_num"
                         v-decorator="['vol24h_num',{ rules: [
                             { required: true, message: '排行榜前几？' },
                             { validator: (rule, value, callback) => {
                                // 检查是否是正整数并在 0 到 50 之间
                                const isPositiveInteger = /^\d+$/.test(value);
                                const numberValue = parseInt(value, 10);
                                if (!isPositiveInteger || numberValue <= 0) {
                                  callback('请输入大于 0 的整数！');
                                } else if (numberValue >= 50) {
                                  callback('请输入小于 50 的整数！');
                                } else {
                                  callback();
                                }
                              },
                              trigger: 'change'
                            },
                          ]
                         },]"/>
              </a-form-item>
            </a-col>

            <a-col :span="14">
              <a-row type="flex" align="middle" style="margin-top: 20px;">
                <a-col :span="24" :md="12" class="col-info">
                  <div class="avatar-info">
                    <p class="font-semibold m-0" style="font-size: 13px;font-weight: 600;color: #141414;">👀 交易员本金监控</p>
                  </div>
                </a-col>
                <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end" v-if="stepOneFormData.trader_platform == '2'">
                  <small class="mr-5" v-if="stepThreeFormData.balance_monitor_visible === false">关闭</small>
                  <small class="mr-5" v-if="stepThreeFormData.balance_monitor_visible === true">开启</small>
                  <a-switch v-model="stepThreeFormData.balance_monitor_visible" @change="onChange"/>
                </a-col>
                <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end" v-else>
                  <small class="mr-5" v-if="stepThreeFormData.balance_monitor_visible === false">OKX暂不支持此功能</small>
                  <small class="mr-5" v-if="stepThreeFormData.balance_monitor_visible === true">开启</small>
                  <a-switch disabled v-model="stepThreeFormData.balance_monitor_visible" @change="onChange"/>
                </a-col>
              </a-row>
            </a-col>
            <a-col :span="14" style="margin-top: 20px;" v-if="stepThreeFormData.balance_monitor_visible">
              <a-form-item class="mb-10" label="最低本金金额" :colon="false">
                <a-input placeholder="eg. 2000（本金低于2000U时，拒绝一切新开仓交易）"
                         v-model="stepThreeFormData.balance_monitor_value"
                         v-decorator="['balance_monitor_value',{ rules: [
                             { required: true, message: '监控最低本金金额？' },
                             { validator: (rule, value, callback) => {
                                // 检查是否是正整数并在 0 到 50 之间
                                const isPositiveInteger = /^\d+$/.test(value);
                                const numberValue = parseInt(value, 10);
                                if (!isPositiveInteger || numberValue <= 100) {
                                  callback('请输入大于 100 的整数！');
                                } else {
                                  callback();
                                }
                              },
                              trigger: 'change'
                            },
                          ]
                         },]"/>
              </a-form-item>
            </a-col>

            <a-col :span="14">
              <a-row type="flex" align="middle" style="margin-top: 20px;">
                <a-col :span="24" :md="12" class="col-info">
                  <div class="avatar-info">
                    <p class="font-semibold m-0" style="font-size: 13px;font-weight: 600;color: #141414;">⚪️ 跟单币种白名单</p>
                  </div>
                </a-col>
                <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
                  <small class="mr-5" v-if="stepThreeFormData.white_list_visible === false">关闭</small>
                  <small class="mr-5" v-if="stepThreeFormData.white_list_visible === true">开启</small>
                  <a-switch v-model="stepThreeFormData.white_list_visible" @change="onChange"/>
                </a-col>
              </a-row>
            </a-col>
            <a-col :span="14" style="margin-top: 20px;" v-if="stepThreeFormData.white_list_visible">
              <a-form-item class="tags-field mb-10" label="输入允许跟单的币种" :colon="false">
                <a-select mode="tags" v-model="stepThreeFormData.white_list" style="width: 100%" placeholder="eg. BTC（跟BTC-USDT-SWAP）" @change="handleWhiteTagChange">
                  <a-select-option v-for="tag in stepThreeFormData.white_tags" :key="tag" :value="tag" selected>
                    {{ tag }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="14">
              <a-row type="flex" align="middle" style="margin-top: 20px;">
                <a-col :span="24" :md="12" class="col-info">
                  <div class="avatar-info">
                    <p class="font-semibold m-0" style="font-size: 13px;font-weight: 600;color: #141414;">⚫️ 跟单币种黑名单</p>
                  </div>
                </a-col>
                <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
                  <small class="mr-5" v-if="stepThreeFormData.black_list_visible === false">关闭</small>
                  <small class="mr-5" v-if="stepThreeFormData.black_list_visible === true">开启</small>
                  <a-switch v-model="stepThreeFormData.black_list_visible" @change="onChange"/>
                </a-col>
              </a-row>
            </a-col>
            <a-col :span="14" style="margin-top: 20px;" v-if="stepThreeFormData.black_list_visible">
              <a-form-item class="tags-field mb-10" label="输入禁止跟单的币种" :colon="false">
                <a-select mode="tags" v-model="stepThreeFormData.black_list" style="width: 100%" placeholder="eg. BTC（不跟BTC-USDT-SWAP）" @change="handleBlackTagChange">
                  <a-select-option v-for="tag in stepThreeFormData.black_tags" :key="tag" :value="tag" selected>
                    {{ tag }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="[24]" style="margin-top: 20px">
            <a-col :span="12">
              <a-button @click="moveStep(-1)" class="px-25"> 上 一 步</a-button>
            </a-col>
            <a-col :span="12" class="text-right">
              <a-button type="primary" class="px-25" @click="submit()"> 提 交</a-button>
            </a-col>
          </a-row>
        </a-form>
      </a-card>
    </div>

  </div>

</template>

<script>
import http from "../utils/http"

export default {
  components: {},
  data() {
    return {
      step: 0,

      // 存储第一步的表单数据
      stepOneFormData: {
        trader_platform: '', // 交易员所在平台
        uniqueName: '', // 交易员平台 ID
        role_type: '1', // 交易员类型 1带单员 2普通用户
      },

      // 存储第二步的表单数据
      stepTwoFormData: {
        id: null, // 跟单 API ID
        api: null, // 跟单 API
        follow_type: '2', // 跟单模式，1固定金额，2固定比例
        multiple_visible: false, // 是否开启跟单倍数
        multiple: 1, // 跟单倍数
        sums: '0', // 单笔跟单金额
        ratio: '0', // 固定比例跟单
        investment: '0', // 智能跟单 投资额（跟单本金）
        lever_set: '1', // 杠杆设置
        leverage: '1', // 杠杆倍数
        reduce_ratio: '0', //减仓比例
        first_open_type: '2', //开仓模式
        uplRatio: '0' //交易员当前收益率
      },

      // 存储第三步的表单数据
      stepThreeFormData: {
        first_order_set: '1', // 首单跟单设置

        posSide_set_visible: false, // 反向跟单开关
        posSide_set: '1', // 反向跟单设置

        fast_mode_visible: true, // 极速跟单开关
        fast_mode: '1', // 极速跟单

        trade_trigger_visible: false,
        trade_trigger_mode: '0', // 跟单触发模式(单笔止盈止损)
        sl_trigger_px: '0', // 止损价百分比
        tp_trigger_px: '0', // 止盈价百分比

        pos_visible: false,
        pos_mode: '0', // 多空策略
        pos_value: 'long', // 多空策略

        vol24h_visible: false, // 24h成交量排行榜跟单开关
        vol24h_mode: '0', // 24h成交量排行榜跟单模式
        vol24h_num: '0', // 排行榜前几

        balance_monitor_visible: false, // 交易员本金监控
        balance_monitor_mode: '0', // 交易员本金监控
        balance_monitor_value: '0', // 交易员本金监控

        white_list_visible: false, //跟单白名单
        white_list_mode: '0',
        white_list: [],
        white_tags: ['BTC', 'ETH', 'SOL', 'PEPE'], // 初始标签列表

        black_list_visible: false, //跟单黑名单
        black_list_mode: '0',
        black_list: [],
        black_tags: ['BTC', 'ETH', 'SOL', 'PEPE'], // 初始标签列表
      },

      user: null,  // 当前用户id

      apiOptions: [], // 用于存储选项数据
      platformOptions: [], // 用于存储选项数据

    }
  },
  computed: {
    // 交易所延迟说明
    traderPlatformNname() {
      switch (this.stepOneFormData.trader_platform) {
        case 1:
          return '欧意';
        case 2:
          return '币安';
        case 3:
          return '平台3';
      }
    },
    traderPlatformDescription() {
      switch (this.stepOneFormData.trader_platform) {
        case 1:
          return '<span style="font-weight: 600">跟单带单员：</span>开仓5分钟延迟，加仓、减仓、平仓无延迟<br><span style="font-weight: 600">跟单普通用户：</span>开仓5分钟延迟，加仓、减仓、平仓无延迟<br><br><span style="font-size: 12px;font-weight: 500;color: red">如需跟单隐藏持仓的带单员，或者0延迟跟单，请选择极速跟单！<a href="/speedcopy">点击前往</a></span>';
        case 2:
          return '<span style="font-weight: 600">跟单公开实盘：</span>开仓、加仓、减仓、平仓无延迟<br><span style="font-weight: 600">跟单隐藏实盘：</span>开仓、加仓、减仓、平仓均有2分钟延迟<br><br><span style="font-size: 12px;font-weight: 500;color: red">如需跟单币安私域带单，或者0延迟跟单，请选择极速跟单！<a href="/speedcopy">点击前往</a></span>';
        case 3:
          return '平台3的延迟说明：系统维护中，可能影响交易速度。';
          // 添加更多平台的提示信息
        default:
          return '<span style="color: red">请选择交易员所在平台</span>';
      }
    },

    isTraderPlatformOne() {
      return this.stepOneFormData.trader_platform === 1;
    },
    isTraderPlatformTwo() {
      return this.stepOneFormData.trader_platform === 2 && this.stepOneFormData.role_type === '2';
    }
  },
  watch: {
    'stepOneFormData.trader_platform': 'handleStepOneChange',
    'stepOneFormData.role_type': 'handleStepOneChange'
  },
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  methods: {
    handleStepOneChange() {
      this.stepTwoFormData.first_open_type = this.stepOneFormData.trader_platform === 1 ? 2 : 1;

      if (this.stepOneFormData.trader_platform !== 1 && this.stepOneFormData.role_type === '2') {
        this.stepTwoFormData.lever_set = '2';
      }
    },
    platformChange(value) {
      this.stepOneFormData.trader_platform = value;
    },
    handleWhiteTagChange(value) {
      // 过滤掉黑名单中的标签
      const filteredTags = value.filter(tag => {
        if (this.stepThreeFormData.black_list.includes(tag)) {
          this.$message.error(`标签 "${tag}" 已存在于黑名单中，不能添加到白名单`);
          return false; // 如果标签在黑名单中，则过滤掉
        }
        return true; // 否则保留标签
      });

      // 更新 white_list
      this.stepThreeFormData.white_list = filteredTags;

      // 打印调试信息
      // console.log("白名单标签:", this.stepThreeFormData.white_list);
    },
    handleBlackTagChange(value) {
      // 过滤掉白名单中的标签
      const filteredTags = value.filter(tag => {
        if (this.stepThreeFormData.white_list.includes(tag)) {
          this.$message.error(`标签 "${tag}" 已存在于白名单中，不能添加到黑名单`);
          return false; // 如果标签在白名单中，则过滤掉
        }
        return true; // 否则保留标签
      });

      // 更新 black_list
      this.stepThreeFormData.black_list = filteredTags;

      // 打印调试信息
      // console.log("黑名单标签:", this.stepThreeFormData.black_list);
    },
    onChange() {
      if (this.stepThreeFormData.posSide_set_visible) {
        this.stepThreeFormData.posSide_set = '2';
      } else {
        this.stepThreeFormData.posSide_set = '1';
      }
      if (this.stepThreeFormData.fast_mode_visible) {
        this.stepThreeFormData.fast_mode = '1';
      } else {
        this.stepThreeFormData.fast_mode = '0';
      }
      if (this.stepThreeFormData.trade_trigger_visible) {
        this.stepThreeFormData.trade_trigger_mode = '1';
      } else {
        this.stepThreeFormData.trade_trigger_mode = '0';
      }
      if (this.stepThreeFormData.pos_visible) {
        this.stepThreeFormData.pos_mode = '1';
      } else {
        this.stepThreeFormData.pos_mode = '0';
      }
      if (this.stepThreeFormData.vol24h_visible) {
        this.stepThreeFormData.vol24h_mode = '1';
      } else {
        this.stepThreeFormData.vol24h_mode = '0';
      }
      if (this.stepThreeFormData.white_list_visible) {
        this.stepThreeFormData.white_list_mode = '1';
      } else {
        this.stepThreeFormData.white_list_mode = '0';
      }
      if (this.stepThreeFormData.black_list_visible) {
        this.stepThreeFormData.black_list_mode = '1';
      } else {
        this.stepThreeFormData.black_list_mode = '0';
      }
      if (this.stepThreeFormData.balance_monitor_visible) {
        this.stepThreeFormData.balance_monitor_mode = '1';
      } else {
        this.stepThreeFormData.balance_monitor_mode = '0';
      }
    },
    async riskTip() {
      this.$confirm({
        title: '风险提示',
        content: (
            <div style="font-weight:bold">
              <p>用户交易记录为实时抓取，显示的交易记录抓取时间为交易成交时间，可能会与交易所展示的交易时间有所不同。</p>
              <p>交易所展示的时间顺序为订单创建的时间顺序，而非订单成交时间顺序，如果是限价单，时间差距可能会很大，交易所展示的顺序也会混乱！</p>
              <p style="color:#EC575BFF">本功能为测试功能，进行实盘跟单前，务必经过模拟盘测试。</p>
              <p style="color:#EC575BFF">确认跟单表示已知并接受风险，请谨慎操作！</p>
            </div>
        ),
        okText: '确认',
        okType: 'danger',
        cancelText: '取消',
        onCancel:() => {
          this.stepOneFormData.role_type = '1';
          console.log('终止操作已取消');
        },
      });
    },
    async chooseSmart() {
      this.$confirm({
        title: '交易员类型切换提示',
        content: (
            <div style="font-weight:bold">
              <p>智能跟单模式为普通用户类型交易员独有。</p>
              <p>智能跟单模式的交易速度会比其他模式慢3到5秒（介意勿选）。</p>
              <p style="color:#EC575BFF">本功能为测试功能，进行实盘跟单前，务必经过模拟盘测试。</p>
              <p style="color:#EC575BFF">确认跟单表示已知并接受风险，请谨慎操作！</p>
            </div>
        ),
        okText: '确认',
        okType: 'danger',
        cancelText: '取消',
        onOk: async () => {
          this.stepOneFormData.role_type = '2';
          this.stepTwoFormData.follow_type = '2';
        },
        onCancel:() => {
          this.stepTwoFormData.follow_type = '2';
          console.log('终止操作已取消');
        },
      });
    },
    // Handles input validation after submission.
    handleSubmit(e) {
      e.preventDefault();
      this.form.getFieldsValue((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values);
        }
      });
    },
    saveFormData() {
      const formValues = this.form.getFieldsValue();
      switch (this.step) {
        case 0:
          Object.assign(this.stepOneFormData, formValues);
          break;
        case 1:
          Object.assign(this.stepTwoFormData, formValues);
          break;
        case 2:
          Object.assign(this.stepThreeFormData, formValues);
          break;
        default:
          break;
      }
    },
    updateFormData() {
      // 假设您的表单是通过 v-decorator 绑定的
      // 更新表单字段以反映保存的数据
      switch (this.step) {
        case 0:
          this.form.setFieldsValue(this.stepOneFormData);
          break;
        case 1:
          this.form.setFieldsValue(this.stepTwoFormData);
          break;
        case 2:
          this.form.setFieldsValue(this.stepThreeFormData);
          break;
        default:
          break;
      }
    },
    moveStep(distance) {
      if (distance > 0) {
        // 当前往前移动（下一步）时，进行表单验证
        this.form.validateFields((err) => {
          if (!err) {
            // 保存当前步骤的表单数据
            this.saveFormData();
            // 如果没有错误，继续到下一步
            this.step += distance;
          }
        });
      } else {
        // 保存当前步骤的表单数据
        this.saveFormData();
        // 后退（上一步）时，不进行表单验证
        this.step += distance;
        console.log(this.platformOptions);
      }
    },
    async fetchApiOptions() {
      try {
        const response = await http.get('/api/apiadd/?token=' + localStorage.getItem("token"));
        console.log("API Response:", response.data);
        if (response.data && Array.isArray(response.data.data)) {
          this.user = response.data.data[0].user;
          this.apiOptions = response.data.data.filter(item => item.status === 1 && item.is_readonly === false).map(item => ({
            api_name: item.api_name,
            id: item.id
          }));
        } else {
          console.error("预期的数据格式不正确");
        }
      } catch (error) {
        console.error("获取数据失败:", error);
      }
    },
    async fetchPlatformOptions() {
      try {
        const response = await http.get('/api/platform/');
        console.log("Response:", response.data);
        if (response.data && Array.isArray(response.data.data)) {
          this.platformOptions = response.data.data
              // .filter(item => item.id === 1 || item.id === 2) // 只保留 id 为 0 和 1 的数据
              .map(item => ({
                platform: item.platform,
                id: item.id
              }));
          // 设置默认选中的平台，比如选中 id 为 1 的平台
          const defaultPlatform = this.platformOptions.find(option => option.id === 1);
          if (defaultPlatform) {
            this.form.setFieldsValue({ trader_platform: defaultPlatform.id })
            this.stepOneFormData.trader_platform = defaultPlatform.id;
          }
        } else {
          console.error("预期的数据格式不正确");
        }
      } catch (error) {
        console.error("获取数据失败:", error);
      }
    },
    async submit() {
      try {
        const data = {
          trader_platform: this.stepOneFormData.trader_platform,
          uniqueName: this.stepOneFormData.uniqueName,
          api: this.stepTwoFormData.api,
          follow_type: this.stepTwoFormData.follow_type,
          multiple: this.stepTwoFormData.multiple,
          sums: this.stepTwoFormData.sums,
          ratio: this.stepTwoFormData.ratio,
          lever_set: this.stepTwoFormData.lever_set,
          leverage: this.stepTwoFormData.leverage,
          first_open_type: this.stepTwoFormData.first_open_type,
          uplRatio: this.stepTwoFormData.uplRatio,
          first_order_set: this.stepThreeFormData.first_order_set,
          posSide_set: this.stepThreeFormData.posSide_set,
          user: this.user,
          role_type: this.stepOneFormData.role_type,
          reduce_ratio: this.stepTwoFormData.reduce_ratio,
          fast_mode: this.stepThreeFormData.fast_mode,
          investment: this.stepTwoFormData.investment,
          trade_trigger_mode: this.stepThreeFormData.trade_trigger_mode,
          sl_trigger_px: this.stepThreeFormData.sl_trigger_px,
          tp_trigger_px: this.stepThreeFormData.tp_trigger_px,
          pos_mode: this.stepThreeFormData.pos_mode,
          pos_value: this.stepThreeFormData.pos_visible ? this.stepThreeFormData.pos_value : '',
          vol24h_mode: this.stepThreeFormData.vol24h_mode,
          vol24h_num: this.stepThreeFormData.vol24h_num,
          balance_monitor_mode: this.stepThreeFormData.balance_monitor_mode,
          balance_monitor_value: this.stepThreeFormData.balance_monitor_value,
          white_list_mode: this.stepThreeFormData.white_list_mode,
          white_list: this.stepThreeFormData.white_list,
          black_list_mode: this.stepThreeFormData.black_list_mode,
          black_list: this.stepThreeFormData.black_list,
        }
        // 检查特定条件，修改 ratio 值
        if (data.role_type == 2 && data.follow_type == 2) {
          data.ratio = '1'; // 智能跟单 强制设置 ratio 为 '1'
        }
        const response = await http.post('/api/taskadd/?token=' + localStorage.getItem("token"), data);
        if (response.data && response.data.code === 0) {
          this.$message.success("创建成功");
          this.$router.push({ path: '/tasklist' });

          try {
            await http.patch('/api/apiadd/' + this.stepTwoFormData.id + '/?token=' + localStorage.getItem("token"), {"status": 2,});
          } catch (patchError) {
            console.error("更新状态时发生错误:", patchError);
            // 处理 http.patch 请求的错误
          }
        } else if(response.data && response.data.code === 1001) {
          this.$message.error(response.data.error);
        }else {
          this.$message.error("创建失败");
        }
      }catch (error) {
        console.error("提交时发生错误:", error);
        // 处理错误
      }
    }

  },
  mounted() {
    this.fetchPlatformOptions();
    this.fetchApiOptions();
    this.updateFormData();
  }

}

</script>

<style lang="scss">

.ant-form-item label {
  font-size: 13px;
  font-weight: 600;
  color: #141414;
}

.ant-form-item-label {
  line-height: 28px;
}

.ant-divider-inner-text {
  font-size: 13px;
  font-weight: 600;
  color: #141414;
}

.no-click {
  pointer-events: none;
}

.radio-with-badge {
  position: relative; /* 设置相对定位基准 */
}

.badge {
  position: absolute; /* 绝对定位 */
  top: -10px; /* 向上偏移，根据需要调整 */
  right: -10px; /* 向右偏移，根据需要调整 */
  //background-color: #f56c6c; /* 背景色 */
  color: #f56c6c; /* 文字颜色 */
  //padding: 2px 5px; /* 内边距 */
  //border-radius: 10px; /* 圆角 */
  font-size: 8px; /* 字体大小 */
}

.form-tag.ant-tag {
  border-radius: 20px;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 3.75px;
  margin-bottom: 3.75px;
  background-color: #3a416f;
  border: 1px solid #3a416f;
  color: #fff;
}
.form-tag.ant-tag .anticon-close {
  color: #fff;
  height: 16px;
  border-left: 1px solid hsla(0,0%,100%,.3);
  padding-left: 5px;
  padding-top: 2px;
  opacity: .75;
}
.form-tag.ant-tag .anticon-close:hover {
  color: #fff;
  opacity: 1;
}
.tags-field .ant-input {
  margin-bottom: 5px;
  margin-top: 4px;
}

.tags-field .ant-select {
  .ant-select-selection__choice__remove i {
    color: #fff;
    height: 16px;
    border-left: 1px solid hsla(0, 0%, 100%, .3);
    padding-left: 5px;
    padding-top: 2px;
    opacity: .75;

    &:hover {
      color: #fff;
      opacity: 1;
    }
  }

  .ant-select-selection__rendered > ul > li:not(.ant-select-search) {
    border-radius: 20px;
    padding: 2px 27px 2px 10px;
    font-size: 12px;
    font-weight: 500;
    margin-right: 3.75px;
    margin-bottom: 3.75px;
    background-color: #3a416f;
    border: 1px solid #3a416f;
    color: #fff;
    line-height: 2;
    height: 30px;
  }

  .ant-select-selection--multiple {
    padding: 8px 10px;
  }
}

.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon .ant-steps-icon-dot, .ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon .ant-steps-icon-dot {
  top: 1px!important;
}
.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot, .ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 0!important;
}
</style>